export default {
  init() {
    $(window).load(function () {
      scrollDownButton()
      activeAnimation()
      close_banner()
      init_smooth_scrolling_anchors()
    })

    $(window).scroll(activeAnimation)

    $('.vc_row').last().addClass('last-row')

    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}

function close_banner() {
  $('.info_box_container_wrapper i').on('click tap', function () {
    $('.info_box_container_wrapper').css('display', 'none')
    $('.overlay').css('display', 'none')
  })
}

function scrollDownButton() {
  $('.scrolldown').each(function () {
    const $button = $(this)
    const $row = $button.parents('.vc_row')
    const $nextRow = $row.next()
    const parentBg = $button.parent().attr('data-bg')

    $button.addClass(parentBg)

    if ($row.hasClass('last-row')) {
      $button.addClass('last-button')
    }

    $button.click(function () {
      $('body, html').animate(
        {
          scrollTop: $row.hasClass('last-row') ? 0 : $nextRow.offset().top,
        },
        1000
      )
    })
  })
}

function activeAnimation() {
  const winST = $(window).scrollTop()
  const winH = $(window).height()
  $('.scrollsection').each(function () {
    const elTop = $(this).offset().top
    if (winST + winH / 2 >= elTop) {
      $(this).addClass('active-scroll')
    } else {
      $(this).removeClass('active-scroll')
    }
  })
}

function init_smooth_scrolling_anchors() {
  $(document).on(
    'click',
    'a[href*="#"]:not([href="#"]):not([href*="#vc_images-carousel"]):not(.dhsv_vc_anchor_menu a):not(.collapse_ac):not([data-vc-tabs]):not([data-vc-accordion])',
    function () {
      if (
        location.pathname.replace(/^\//, '') ==
          this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        var targetOffset = -150
        var target = $(decodeURIComponent(this.hash))
        //var scrollTop = $(window).scrollTop()
        target = target.length
          ? target
          : $('[name="' + this.hash.slice(1) + '"]')
        if (target.length) {
          var target_pos = target.offset().top
          $('html,body').animate(
            {
              scrollTop: target_pos + targetOffset,
            },
            1000
          )
          return false
        }
      }
    }
  )
}
