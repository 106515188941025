import React from 'react'
import PropTypes from 'prop-types'
/* Ref: https://github.com/google-map-react/google-map-react/blob/master/API.md */
import GoogleMapReact from 'google-map-react'
import Marker from './Marker'

class GoogleMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    const {
      data: { marker, options, styles, key },
    } = props

    this.MAP = {
      defaultZoom: options.defaultZoom ? Number(options.defaultZoom) : 16,
      defaultCenter: { lat: Number(marker.lat), lng: Number(marker.lng) },
      options: {
        styles: styles,
        maxZoom: options.maxZoom ? Number(options.maxZoom) : 18,
        minZoom: options.minZoom ? Number(options.minZoom) : 6,
        minZoomOverride: options.minZoom ? true : false,
        /* MAP BEHAVIOR */
        scrollwheel: false,
        draggable: true,
        keyboardShortcuts: false,
        disableDoubleClickZoom: false,
        /* CONTROLS */
        zoomControl: true,
        panControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      },
      key: key,
    }

    this.renderOverlay = this.renderOverlay.bind(this)
  }

  renderOverlay() {
    const { overlay } = this.props
    if (overlay) {
      return <div className="overlay" />
    } else {
      return null
    }
  }

  render() {
    const { MAP } = this
    const {
      data: { marker },
    } = this.props

    return (
      <div className="mapwrapper" style={{ height: '100vh' }}>
        <GoogleMapReact
          ref={e => {
            this.map = e
          }}
          defaultZoom={MAP.defaultZoom}
          defaultCenter={MAP.defaultCenter}
          options={MAP.options}
          onChange={this.handleMapChange}
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{ key: MAP.key }}
        >
          <Marker lat={marker.lat} lng={marker.lng} />
        </GoogleMapReact>
        {this.renderOverlay()}
      </div>
    )
  }
}

GoogleMap.propTypes = {
  data: PropTypes.object.isRequired,
  overlay: PropTypes.object,
}

export default GoogleMap
